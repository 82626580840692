<div class="sidebar"   
  (mouseenter)="isSidebarHovered = true" 
  (mouseleave)="isSidebarHovered = false"
  [ngClass]="{ 'sidebar-open': isSidebarOpen }"
  >
  <div class="logo">
    <a class="simple-text logo-mini">
      <div class="logo-img">
        <img src="./assets/img/white-logo.png" />
      </div>
    </a>
    <a class="simple-text logo-normal">
      Almajdouie
    </a>
    <div class="navbar-minimize">
      <button id="minimizeSidebar" class="btn btn-simple btn-icon btn-neutral btn-round" (click)="minimizeSidebar()">
        <i class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
        <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>
      </button>
    </div>
  </div>
  <div class="sidebar-wrapper" [ngClass]="{ 'sidebar-wrapper-hovered': isSidebarHovered || isSidebarOpen }">
    <div class="user">
      <div class="photo"><img src="../assets/img/default-avatar.png" /></div>
      <div class="info">
        <a>
          <span class="text-primary text-center"> {{user.roleName}} </span>
          <span> {{user.name}} </span>
        </a>
        <a (click)="logout()">
          <span class="text-center btn"> Logout </span>
        </a>
      </div>
    </div>
    <ul class="nav">
      <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
        <!--If is a single link-->
        <a [routerLink]="[menuitem.path]"
          *ngIf="menuitem.type === 'link' && this.user?.permissions?.[menuitem.permission]?.includes('VIEW')">
          <i class="now-ui-icons {{ menuitem.icontype }}"></i>
          <p>{{ menuitem.title }}</p>
        </a>
        <!--If it have a submenu-->
        <a data-toggle="collapse" href="#{{ menuitem.collapse }}"
          *ngIf="menuitem.type === 'sub' && hasChildPermission(menuitem)" (click)="
            myFunc($event, menuitem);
            menuitem.isCollapsing === undefined &&
              (menuitem.isCollapsed = !menuitem.isCollapsed)
          " [attr.aria-expanded]="!menuitem.isCollapsed">
          <i class="now-ui-icons {{ menuitem.icontype }}"></i>
          <p>{{ menuitem.title }}<b class="caret"></b></p>
        </a>

        <!--Display the submenu items-->
        <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="menuitem.type === 'sub'"
          [ngbCollapse]="menuitem.isCollapsed">
          <ul class="nav">
            <li routerLinkActive="active" *ngFor="let childitem of menuitem.children">
              <a [routerLink]="[menuitem.path, childitem.path]"
                *ngIf="this.user?.permissions?.[childitem.permission]?.includes('VIEW')">
                <span class="sidebar-mini-icon">{{ childitem.ab }}</span>
                <span class="sidebar-normal">{{ childitem.title }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>